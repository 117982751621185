@media screen and (min-width: 500px) {
  .p-font {
    font-size: x-large !important;
  }
}
@media screen and (max-width: 500px) {
  .p-font {
    font-size: large !important;
  }
}
body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
footer {
  margin-top: auto;
}