.gray-form {
  /* background-color: lightgray !important; */
  background-image: linear-gradient(180deg, rgba(68, 145, 117, 0.3), rgba(255, 154, 96, 0.3)) !important;
  border-radius: 10px !important;
  font-size: larger !important;
}
.checkout-Button {
  background-color: #ed6f26 !important;
  border: 0 !important;
  font-size: larger !important;
}

.form-text {
  color: black !important;
  font-size: 14px !important;
}

.section-title {
  font-size: x-large !important;
}

.textarea-style {
  font-size: medium !important;
}